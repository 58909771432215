import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { PortFormFrameComponent } from './port-form-frame.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [
    PortFormFrameComponent
  ],
  exports: [
    PortFormFrameComponent
  ]
})
export class PortFormFrameModule { }
