import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SystemDirectivesModule } from 'app/system/directives/system-directives.module';

import { OceanVesselFormFrameComponent } from './ocean-vessel-form-frame.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SystemDirectivesModule
  ],
  declarations: [
    OceanVesselFormFrameComponent
  ],
  exports: [
    OceanVesselFormFrameComponent
  ]
})
export class OceanVesselFormFrameModule { }
