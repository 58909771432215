import { ApiServiceOptions } from 'app/system/services/model-api.service';
import { ViewModelService } from 'app/system/services/view-model.service';

import { Observable } from 'rxjs';

import { IApiSingleDataResult } from 'app/system/services/api.service';

import { IBillLading } from 'app/models/bill-lading.model';
import { IBillLadingViewModel } from 'app/routes/bill-lading/bill-lading.viewmodel';

@ApiServiceOptions({ description: 'Bill Of Lading', controller: 'billlading' })
export class BillLadingService extends ViewModelService<IBillLadingViewModel> {

  public send(billLadingId: number): Observable<IApiSingleDataResult<IBillLading>> {

    return this.post({
      id: billLadingId,
      action: 'send',
      errorTitle: 'Cannot send'
    });
  }
}
