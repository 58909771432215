import { Input, Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControl } from '@angular/forms';

import { LocalNumberService } from 'app/system/services/local-number.service';

@Component({
  selector: 'hs-input',
  templateUrl: './local-number-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocalNumberInputComponent),
      multi: true
    }
  ]
})
export class LocalNumberInputComponent implements ControlValueAccessor, OnInit {

  @Input() private inputId: string;
  @Input() private description: string;
  @Input() private unit: string;
  @Input() private isInvalid: boolean;
  @Input() private placeholder: string;

  @Input() private precision: number;
  @Input() private small: string;

  private control: FormControl;
  private _onTouched = () => { };
  private _propagateChange = (_: any) => { };

  constructor(
    private service: LocalNumberService
  ) {
    this.precision = service.format.precision;
  }

  public ngOnInit() {

    let str = '';
    for (let i=0;i<this.precision;i++) {
      str+='0';
    }
    const decPart = this.precision > 0 ? `${this.service.format.decimal}${str}` : '';
    this.small = `Ex.: 1${this.service.format.thousands}000${decPart} = 1000`;

    this.control = new FormControl([0]);
    this.control.valueChanges.subscribe(s => {

      let masked = this.maskValue(s)

      this.control.setValue(masked, {
        emitEvent: false
      });

      const escapeRegExp = (text: string) => {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
      };

      let result = masked;
      result = result.replace(new RegExp(escapeRegExp(this.service.format.thousands), 'g'), '');
      result = result.replace(/\,/g, '.');
      this._propagateChange(parseFloat(result));
    });
  }

  private maskValue(value: string | number) {

    return this.service.maskValue(value, {
      precision: this.precision
    });
  }

  public writeValue(value: any): void {

    this.control.setValue(this.maskValue(value));
  }

  public registerOnChange(fn: any): void {

    this._propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {

    this._onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {

    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }
}
