import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';

import { FormService } from 'app/system/services/form.service';
import { IPortViewModel } from 'app/routes/port/port.viewmodel';

@Component({
  selector: 'app-port-form-frame',
  templateUrl: './port-form-frame.component.html'
})
export class PortFormFrameComponent implements OnInit {

  @Input() data: IPortViewModel;

  private submitted = false;
  private form: FormGroup;

  constructor(
    private builder: FormBuilder,
    private formService: FormService
  ) { }

  ngOnInit() {

    this.form = this.initPortFormGroup();
    this.form.patchValue(this.data);
  }

  private initPortFormGroup() {

    return this.builder.group({
      port: this.builder.group({
        id: [0],
        name: [null, [Validators.required]],
        code: []
      })
    });
  }

  public submit(): Observable<IPortViewModel> {

    this.submitted = true;
    return new Observable<IPortViewModel>(s => {
      if (this.form.valid) {
        s.next(this.form.value);
        s.complete();
      }
    });
  }
}
