export enum ConsolidationType {

  FCL = 1,
  LCL = 2
}

export const ConsolidationTypeNames = {
  1: 'FCL',
  2: 'LCL'
};
