import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router, CanActivate, ActivatedRoute } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { IApiSingleDataResult, IQueryParam } from 'app/system/services/api.service';
import { InfoModalService } from '../components/info-modal/info-modal.service';
import { IViewModel, ViewModelService } from 'app/system/services/view-model.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ResolverService<T extends IViewModel> implements CanActivate, Resolve<IApiSingleDataResult<T>> {

  private result: T;

  constructor(
    private router: Router,
    private infoModal: InfoModalService,
    private service: ViewModelService<T>,
    private paramName: string
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

    let observable: Observable<IApiSingleDataResult<T>>;
    const param = route.paramMap.get(this.paramName);

    let queryParams: IQueryParam[];
    for (const key in route.queryParams) {
      if (route.queryParams[key]) {
        if (!queryParams) {
          queryParams = [];
        }
        queryParams.push({
          name: key,
          value: route.queryParams[key]
        });
      }
    }

    if (param === 'new') {
      observable = this.service.create({ raiseException: true, silentErrorModal: true, queryParams: queryParams });
    } else {
      observable = this.service.get({ id: param, raiseException: true, silentErrorModal: true, queryParams: queryParams });
    }

    this.result = null;
    return observable.pipe(
      map(x => {
        this.result = x && x.data;
        return true;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 404) {
          this.router.navigate(['/notfound']);
          return of(true);
        } else if (error && error.status === 401) {
          this.router.navigate(['/login']);
          return of(true);
        } else {
          const apiError = this.service.service.getApiErrorFromCatch(error);
          const errorResult = this.service.service.getApiErrorModal(apiError);
          this.infoModal.show(errorResult);
          return of(false);
        }
      })
    );
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T> {

    return of(this.result);
  }
}
