import { AbstractControl, Validators } from '@angular/forms';
import { VGMMethod } from 'app/models/enum/vgm-method.enum';

export const textValidator = (maxLines: number, maxCols: number) => {
  return (control: AbstractControl): { [s: string]: boolean } => {

    const value = (control.value || '').trim();
    let invalid = false;
    if (value !== '') {
      const lines: string[] = (<string>value).split('\n');
      if (maxLines && lines.length > maxLines) {
        invalid = true;
      } else if (maxCols) {
        lines.every(line => {
          if (line.length > maxCols) {
            invalid = true;
            return false;
          } else {
            return true;
          }
        });
      }

      if (invalid) {
        return { invalidItem: true };
      }
    } else {
      return null;
    }
  };
};

export const numberValidator = (control: AbstractControl): { [s: string]: boolean } => {

  if ((<string>control.value || '').indexOf('_') !== -1) {
    return { invalidNumber: true };
  } else {
    return null;
  }
};

export const listValidator = (list: string[]) => {
  return (control: AbstractControl): { [s: string]: boolean } => {

    const value = control.value || '';
    if (value !== '' && !list.some(x => {
      return x.toLowerCase() === control.value.toLowerCase();
    })) {
      return { invalidItem: true };
    } else {
      return null;
    }
  };
};

export const validChars = Validators.pattern(/^[\u0020-\u007E\u00A0-\u00FF\u20A0-\u20BF\r\n]+$/);

export const vgmContainerValidator = (control: AbstractControl): { [s: string]: boolean } => {

  const method = control.get('method').value;
  const cargoWeight = control.get('cargoWeight').value;
  const totalWeight = control.get('totalWeight').value;
  const weightUnit = control.get('weightUnit').value;
  const verifiedAt = control.get('verifiedAt').value;

  let result = null;

  const hasCargoWeight = method === VGMMethod.Method2;

  if (method || hasCargoWeight || totalWeight || weightUnit || verifiedAt) {
    if (!method) {
      result = result || {};
      result.method = true;
    }

    if (hasCargoWeight && !cargoWeight) {
      result = result || {};
      result.cargoWeight = true;
    }

    if (!totalWeight) {
      result = result || {};
      result.totalWeight = true;
    }

    if (!weightUnit) {
      result = result || {};
      result.weightUnit = true;
    }

    if (!verifiedAt) {
      result = result || {};
      result.verifiedAt = true;
    }
  }

  return result;
};
