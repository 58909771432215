import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';

import { FormService } from 'app/system/services/form.service';
import { ICustomerViewModel } from 'app/routes/customer/customer.viewmodel';

@Component({
  selector: 'app-customer-form-frame',
  templateUrl: './customer-form-frame.component.html'
})
export class CustomerFormFrameComponent implements OnInit {

  @Input() data: ICustomerViewModel;

  private submitted: boolean = false;
  private form: FormGroup;

  private priorName: string;

  constructor(
    private builder: FormBuilder,
    private formService: FormService
  ) { }

  ngOnInit() {

    this.form = this.initCustomerFormGroup();
    if (this.data) {
      this.form.patchValue(this.data);
    }
  }

  private initCustomerFormGroup() {

    const result = this.builder.group({
      customer: this.builder.group({
        id: [0],
        name: [null, [Validators.required]],
        address: [null, [Validators.required]],
        shipperExporter: [true],
        consignee: [true],
        notify: [true]
      })
    });

    const customer = result.get('customer');
    const name = customer.get('name');
    const address = customer.get('address');

    name.valueChanges.subscribe(value => {
      if ((this.priorName || '') === '' || ((address.value || '') === (this.priorName || ''))) {
        address.setValue(name.value);
      }
      this.priorName = name.value;
    });

    return result;
  }

  public submit(): Observable < ICustomerViewModel > {

  this.submitted = true;
  return new Observable(s => {
    if (this.form.valid) {
      s.next(this.form.value);
    }
    s.complete();
  });
}
}
