import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

import { LocalNumberInputComponent } from "./local-number-input.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  declarations: [
    LocalNumberInputComponent
  ],
  exports: [
    LocalNumberInputComponent
  ]
})
export class LocalNumberInputModule { }
