export enum BillLadingStatus {
    None = 0,
    Editing = 1,
    Sent = 2,
    Received = 3,
    Rejected = 4,

    /* enums usados só para filtro na grade */
    NewBooking = 5,
    Canceled = 6
}
